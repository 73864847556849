import React from 'react'
import BookDemoBanner from './BookDemoBanner/BookDemoBanner'
import ContactForDemo from '../../Page/Home/ContactForDemo/ContactForDemo'
 
import BookDemoRegForm from '../../Login/BookDemo/BookDemoRegForm/BookDemoRegForm'

const BookDemo = () => {
    return (
        <div className='BookDemo'>
            <BookDemoBanner />
            <div className="Book-RegForm">
            <BookDemoRegForm/>

            </div>
            <ContactForDemo/>
        </div>
    )
}

export default BookDemo