import Rating from "../../Components/Shared/Rating/Rating"
import Pralad from "../Assests/ClientStory/IMG20230509090556.jpg"
import Pralad1 from "../Assests/About/Team/_d141f5c8-8365-4ee9-9dee-9c287d5f68ce.jpeg"
const rating = 8.5;
const ClientslidesData = [

    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Profile: Pralad,
        Name:"Pralad Chaudhary",
        Details: "President of Nepal"
    },
    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Profile: "",
        Name:"Kshitij Dhamala",
        Details: "Figma designer"
    },
    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Name:"",
        Profile: "",
        Details: ""
    },


    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Name:"",
        Profile: "",
        Details: ""
    },
    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Name:"",
        Profile: "",
        Details: ""
    },
    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Profile: Pralad1,
        Name:"GulabJamun",
        Details: "King of Sweets",
    },
    {
        Note: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra. . Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra.  Lorem ipsum dolor sit amet consectetur. Sit eget morbi et proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor scelerisque iaculis suspendisse pharetra “",
        // Ratings:  <Rating rating={rating} />,
        Name:"",
        Profile: "",
        Details: ""
    },
]
export default ClientslidesData;