

import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavigationaDesk.css';
import { NavLink } from 'react-router-dom';
import  { ProductData } from "../../DummyData/NavData/NavPath"
import Logo1 from "../../Assests/Logo/WhatsApp Image 2024-10-08 at 16.12.39_95c3215c.jpg"
import Logo from '../../Shared/Logo';


const NavigationaDesk = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(true);
  const toggleNavigationaDesk = () => {
    setIsOpen(!isOpen);
    setHoveredItem(true)
  };
  const removeActive = () => {
    setIsOpen(false);
    setHoveredItem(true)
  };
  const HandleonclickProductPage = () => {
    setHoveredItem(!hoveredItem)
    setIsOpen(true);

  }
  
  return (
    <nav className="navigationadesk">
      <div className="navigationadesk-container">
        <h1 className="navigationadesk-logo">
          {/* <Logo/> */}

            <img src={Logo1} alt='' style={{ width: "100%", height: "100%" }} />
        </h1>
        <div className="navigationadesk-icon" onClick={toggleNavigationaDesk}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={isOpen ? 'nav-navigationadesk active' : 'nav-navigationadesk'}>

         


            <div className="nav-itemnavigationadesk"
                >


              



              

                <NavLink className="nav-linksnavigationadesk" to="/" onClick={removeActive}>Home</NavLink>
                <NavLink className="nav-linksnavigationadesk" to="/Aboutsus" onClick={removeActive}>Abouts</NavLink>
                <NavLink className="nav-linksnavigationadesk" to="/Service" onClick={removeActive}>Service</NavLink>

                
























                <div>
                  <NavLink className="nav-linksnavigationadesk" to="#" onClick={HandleonclickProductPage}>
                    Products
                  </NavLink>

                  {hoveredItem ? "" : <>
                    <div className="Sub-Menus">

                      {ProductData.map((each, index) => (
                        <NavLink to={`product/:${index}`} className="Sub-Link" onClick={removeActive}>


                          <div className="Sub-NavDesk" key={index}
                          >




                            <div className="Sub-Menu-Photo">
                              <img src={each.Icon} alt='' />

                            </div>

                            <div className="Sub-Menu-Lable-Def">
                              <p>{each.Lable}
                              </p>

                              <div className="Sub-Menu-Def">
                                <p>{each.Defination}</p>
                              </div>
                            </div>

                          </div>
                        </NavLink>


                      ))}


                    </div></>}


                </div>

                <NavLink className="nav-linksnavigationadesk" to="/Testimonials" onClick={removeActive}>Testimonials</NavLink>
                <NavLink className="nav-linksnavigationadesk" to="/Contact" onClick={removeActive}>Contact</NavLink>
                <NavLink className="nav-linksnavigationadesk" to="/FAQS" onClick={removeActive}>FAQS</NavLink>
                <NavLink className="nav-linksnavigationadesk" to="/Blogs" onClick={removeActive}>Blogs</NavLink>


              





















              {/* <div className="Submenu-Desk"> */}


             
            </div>

          <div className="LoginNavigationaDesk">
            <nav className='Border1NavigationaDesk'  >

              <NavLink to="/BookDemo" ><button className="Login-button" >Book a Demo</button></NavLink>

            </nav>
            <nav className='Border2NavigationaDesk'  >

              <NavLink to="/Login" ><button className="Login-button1" >Login</button></NavLink>

            </nav>
          </div>
        </ul>
      </div>
    </nav>
  );
};
export default NavigationaDesk