import Pralad from "../Assests/About/Team/IMG20230427094115.jpg"
import Blog from "../Assests/Blogs/WhatsApp Image 2024-09-03 at 12.35.23_742cdc09.jpg"
export const BlogsData = [
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
    {
        Photo: Blog,
        Heading: "Dynamic Emr Providing a Top Notch management software for hospital",
        MetaDescription: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo quod magnam nam? Velit voluptatibus harum laboriosam tempore corporis tenetur, illum debitis quisquam quam saepe obcaecati?",

        Description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur qui omnis voluptas fugiat. Quam illum voluptas doloribus ex explicabo provident minus! Reprehenderit consequuntur cumque officiis enim et. Tenetur aspernatur veritatis adipisci non maxime, libero, minus, aliquid dolores voluptate possimus mollitia deserunt! Labore exercitationem accusantium, qui eos est nostrum blanditiis in, nulla tempora excepturi fugiat nesciunt dolores expedita, eaque quisquam optio possimus beatae! Provident modi quos adipisci enim quas incidunt velit inventore, ad aliquam iusto voluptate similique numquam culpa iure, expedita exercitationem quam assumenda recusandae nam asperiores delectus. Quidem vero debitis commodi fuga pariatur numquam amet sunt! Quidem dolore nesciunt sint similique odio ut excepturi veniam unde iste voluptatum, nam ducimus qui distinctio natus aut quas voluptates molestiae recusandae vero quae ad nisi officia animi? Doloribus corporis, alias quidem tempore asperiores officia ex. Fuga dignissimos necessitatibus voluptatum dicta eaque ipsam facilis voluptatem, magni ad nostrum, maxime excepturi consequatur suscipit eveniet laborum sed nesciunt? Obcaecati dicta ab fugit at nemo iste minus culpa, quod deleniti consequuntur iusto, veritatis alias error aut, voluptate cupiditate eaque! Odio officiis mollitia perferendis! Iusto, ullam assumenda neque dignissimos nostrum mollitia vel ratione esse cumque nulla accusantium id, eveniet explicabo repudiandae dolorum, molestiae eos voluptas impedit aut reprehenderit earum? Explicabo nostrum modi rerum beatae tenetur, architecto, obcaecati quam expedita inventore illum ad iure quo commodi est similique delectus consequatur cumque. Dignissimos omnis laudantium reprehenderit veniam accusantium facilis debitis corrupti, sunt odio facere doloremque praesentium! Possimus repellat consequuntur tempora eius! Reiciendis rerum odit quae deserunt consequuntur a ex dicta.",
        Profile:Pralad,
        Name: "Pralad Chaudhary",
        Date: "2082/03/15",

    },
  

];