import A from "../Assests/OurService/ic_outline-settings.png"
export const OurServiceNewsTemplateDataMid = [
    {
        Icon: A,
        Lable: "Reliable Software",
        Defination: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat ",
    },
    {
        Icon: A,
        Lable: "Reliable Software",
        Defination: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat ",
    },
];

export const OurServiceNewsTemplateDataRight = [
    {
        Lable: "Our Vision",
        Defination: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat ",
    },
    {
        Lable: "Our Vision",
        Defination: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat ",
    },
    {
        Lable: "Our Vision",
        Defination: "“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat ",
    },
];