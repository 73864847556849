import OurProduct from "../../Page/FAQS/Page/OurProduct/OurProduct";
import Discount from "../../Page/FAQS/Page/Discount/Discount";
import OurSoftwareInfo from "../../Page/FAQS/Page/OurSoftwareInfo/OurSoftwareInfo";
import PaymentCreadit from "../../Page/FAQS/Page/PaymentCreadit/PaymentCreadit";

export const Faqsdata = [
   
    {
        Lable:"Our Product?",
        Component:<OurProduct/>,
    },
     {
        Lable:"Discount?",
        Component:<Discount/>,
    },
    {
        Lable:"Our Software Info?",
        Component:<OurSoftwareInfo/>,
    },
    {
        Lable:"Payment Creadit?",
        Component:<PaymentCreadit/>
    },
    
];
export default Faqsdata
