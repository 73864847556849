import React from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
const StarRating = () => {
  return (
    <div className='StarRating'>
        <Stack direction="row" spacing={2}>
            <Rating name="half-rating-read" defaultValue={3.5} value={2.5} precision={0.5} readOnly />
        </Stack>
    </div>
  )
}

export default StarRating