import React from 'react'
import "./HomeContact.css"
import { NavLink } from 'react-router-dom'
const HomeContact = () => {
    return (
        <div className='HomeContact' data-aos="zoom-out-left">
            <div className="HomeContact-Des">
                <div className="HomeContact-Head">
                    <p data-aos="flip-right">We would love to talk
                        With <span style={{
                            color: "#E75303"
                        }}>You</span> </p>
                </div>
                <div className="HomeContact-Def">
                    <p data-aos="flip-right">Lorem ipsum dolor sit amet consectetur. Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phasellus ultricies quis nibh quis consectetur. Morbi lectus sed commodo duis maecenas. Risus sapien diam mattis sed elementum non.</p>
                </div>

            </div>
            <div className="HomeContact-2" >
                <NavLink to="/Contact"  ><button data-aos="flip-right"className='HomeContact-But' style={{ backgroundColor: "#014A97" }}>Contact Us</button></NavLink>
            </div>
        </div>
    )
}

export default HomeContact