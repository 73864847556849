import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavData from '../DummyData/NavData/NavData';
import Footer from '../Footer/Footer';
import Navigation from '../Navigation/Navigation';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import Footer1 from '../Footer/Footer1';

const App = () => {
    return (
        <BrowserRouter>
            <Navigation />
            <Routes>
                {NavData.map((each, index) => (
                    <Route key={index} path={each.Path} element={each.Component} />
                ))}
            </Routes>
            <ProgressCircle />
            {/* <Footer /> */}
            <Footer1/>
        </BrowserRouter>
    );
};

export default App;
