import React from 'react'
import BannerTextSlider from './BannerTextSlider/BannerTextSlider'
import "./BannerSlider.css"
import Searchicon from "../../../Assests/Logo/akar-icons_search.png"
import Typewriter from 'typewriter-effect';
const BannerSlider = () => {
  return (
    <div className='BannerSlider-Main'>
      <div className="BannerSliders">

        <div className="BannerSliderText"><BannerTextSlider /></div>
        <div style={{
        }} className="BannerSlider-Pra">
        <Typewriter
                options={{
                    strings: ['<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum dolore veritatis voluptatem nobis odit quasi quis voluptatum assumenda ipsa pariatur illum, atque vitae vel, enim commodi, ratione alias accusamus est.</p>'],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                    delay: 50,
                    pauseFor: 2000,
                    html: true,
                }}
               
            />


          {/* <p className="BannerSlider-Pra" style={{ color: "white" }}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum dolore veritatis voluptatem nobis odit quasi quis voluptatum assumenda ipsa pariatur illum, atque vitae vel, enim commodi, ratione alias accusamus est.</p> */}
        </div>
        <div className='BannerSeachbox'>
          <input className="BannerSlider-Search" style={{
            height: "57px",
            width: "70%",
            border: "1px solid #FF7A00",
            borderRadius: "30px",
            backgroundColor: "transparent",
            padding: "8px 20px 8px 20px",
            color: "white"

          }} type='text' placeholder='Searching for Anything' />
          <img className='Banner-Search-Icon' type="submit" src={Searchicon} alt='' />
        </div>
      </div>
    </div>

  )
}

export default BannerSlider