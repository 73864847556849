import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import AboutTeamSliderData from "../../../../../DummyData/AboutTeamSliderData"
import "./AboutTeamTab.css"
const AboutTeamTab = () => {
    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow nextTab" onClick={onClick}>
                <FaArrowRight />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow prevTab" onClick={onClick}>
                <FaArrowLeft />
            </div>
        );
    };


    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 2,
        centerMode: true,
        centerPadding: 0,
        autoplay: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };


    return (
        <div className="TeamStorySlider">
            <div className="TeamStorySlider-Header" data-aos="flip-left">
                <p>Our Success Team  </p>
            </div>
            <div className="TeamStorySlider-SubSlider">

                <Slider dots {...settings}>
                    {/* <div className="TeamStorySlider-Inner"> */}


                    {AboutTeamSliderData.map((each, idx) => (





                        <div className='AboutTeamTab'  key={idx} data-aos="flip-right">


                            <img src={each.Photo} alt='' />
                            <div className="About-team-Name">
                                <p>{each.Name}</p>

                            </div>
                            <div className="About-team-Position">
                                <p >{each.Position}</p>

                            </div>
                        </div>






                    ))}
                    {/* </div> */}

                </Slider>
            </div>

        </div>

    )
}

export default AboutTeamTab


