import React from 'react'
import "./HosAnalysisFeature.css"
import {FeaturesSliderData} from "../../../../DummyData/FeaturesSliderData"
const HosAnalysisFeature = () => {
    return (
        <div className='HosAnalysisFeature'>

            <div className="HosAnalysisFeature-Feature">

                {/* <div className='Slider-Main-Container'> */}

                    {FeaturesSliderData.map((each, index) => {
                        return (
                            // <div className="Slider-Image-Lable-Container" key={index}>
                                <div className="HosAnalysisFeature-Feature-Inner" data-aos="fade-left">
                                    <div className='HosAnalysisFeature-Feature-Image'>
                                        <img src={each.Image} alt=""
                                        />
                                    </div>
                                    <div className="HosAnalysisFeature-Feature-Lable">
                                        <p>{each.Lable}</p>
                                    </div>
                                    <div className="HosAnalysisFeature-Feature-Defination">
                                        <p>{each.Defination}</p>
                                    </div>

                                    <p>{each.Name}</p>
                                </div>

                        );
                    })}
                    

                {/* </div> */}
            </div>
            <div className="HosAnalysisFeature-Def" data-aos="zoom-in-right">
                <h2>
                    Real Time Data Analysis
                </h2>
                <p>
                    Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.
                </p>

            </div>
        </div>
    )
}

export default HosAnalysisFeature