import React from 'react'
import "./HosAnalysis.css"
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const HosAnalysis = () => {
    return (
        <div className='HosAnalysis'>
            <div className="HosAnalysis-Photo" data-aos="zoom-in-right">
                <img src={Photo} alt='' />
            </div>
            <div className="HosAnalysis-Def" data-aos="zoom-in-right">
                <h2>
                    Real Time Data Analysis
                </h2>
                <p>
                    Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.
                </p>

            </div>
        </div>
    )
}

export default HosAnalysis