import React from 'react'
import ClientStoryTab from './ClientStoryTab/ClientStoryTab';
import ClientStoryMob1 from './ClientStoryMob1/ClientStoryMob1';
import { useMediaQuery } from 'react-responsive';

const ClientStoryMobTab = () => {
  const isDesktopOrLaptopa1 = useMediaQuery({ query: '(max-width: 878px)' });
  // console.log(width)
  return (
    <div>
      {isDesktopOrLaptopa1 ? <ClientStoryMob1 /> : <ClientStoryTab />}

    </div>
  )
}

export default ClientStoryMobTab