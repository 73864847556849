import React from 'react'
import "./Banner.css"
import Typewriter from 'typewriter-effect';
import Next from "../../../../Components/Assests/phone-call.png"
import Next1 from "../../../../Components/Assests/mail-inbox-app.png"
const Banner = () => {
  return (


    <div className="ConSlogan" style={{
      alignContent: "center"
    }}>
      <img src={Next} style={{width:"5vw",position:"relative", right:"30%"}} alt=''/>
     

       <Typewriter
        options={{
          strings: [' <p>Get in touch with us!</p></br><p>Feel free to call us for queries!</P>'],
          autoStart: true,
          loop: true,
        }}

      />
      
      <img style={{width:"5vw",position:"relative", left:"35%"}} src={Next1} alt=''/>
    </div>
  )
}

export default Banner