import React from 'react'
import ClientStoryDesk from './ClientStoryDesk/ClientStoryDesk'
// import ClientStoryMob from './ClientStoryMob/ClientStoryMob'
import ClientStoryMobTab from './ClientStoryMobTab/ClientStoryMobTab';
import { useMediaQuery } from 'react-responsive';


const ClientStorySlider = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <div>
      {isDesktopOrLaptop? <ClientStoryMobTab/> : <ClientStoryDesk />}

    </div>
  )
}

export default ClientStorySlider