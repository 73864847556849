
import React from 'react'
import "./AboutDef.css"
import A1 from "../../../Assests/About/Rectangle 62.png"
const AboutDef = () => {
    return (
        <div className='AboutDef'>

            <div className="AboutDes" data-aos="fade-left">
                <p>
                ConsultUs provides consulting services that help business owners and leaders build a more valuable business. We worked with their founder to build a professional, modern site that follows the StoryBrand framework to clearly communicates the value it adds to potential clients
                </p>
            </div>
            <div className="AboutPho" data-aos="fade-right">
                <img src={A1} alt='' />
            </div>
        </div>
    )
}

export default AboutDef