import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './NavigationMob.css';
import { NavLink } from 'react-router-dom';
import { ProductData } from "../../DummyData/NavData/NavPath"
import Logo1 from "../../Assests/Logo/logo192.png"
import Search from "../../Assests/Logo/akar-icons_search.png"

const NavigationMob = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search1, setSearch] = useState(false)
  const [hoveredItem, setHoveredItem] = useState(true);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setHoveredItem(true)

  };
  const removeActive = () => {
    setIsOpen(false);
    setHoveredItem(true)

  };
  const HandleOnclick = () => {
    setSearch(!search1)


  }
  const HandleonclickProductPage = () => {
    setHoveredItem(!hoveredItem)
    setIsOpen(true);

  }
  useEffect(() => {
    setHoveredItem(true)
  }, [])
  return (
    <nav className="navbar">
      <div className="navbar-container">

        <div className="menu-icon" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
          <div className="nav-item">

            <NavLink className="nav-links" to="/" onClick={removeActive}>Home</NavLink>
            <NavLink className="nav-links" to="/Aboutsus" onClick={removeActive}>Abouts</NavLink>
            <NavLink className="nav-links" to="/Service" onClick={removeActive}>Service</NavLink>

            

























            <div>
              <NavLink className="nav-links" to="#" onClick={HandleonclickProductPage}>
                Products
              </NavLink>

              {hoveredItem ? "" : <>
                <div className="Sub-Menus-Mob">

                  {ProductData.map((each, index) => (
                    <NavLink to={`product/:${index}`} >

                      <div className="Sub-Nav-Mob dropdown-item" key={index} onClick={removeActive}>

                        <div className="Sub-Menu-Photo-Mob">
                          <img src={each.Icon} alt='' />

                        </div>

                        <div className="Sub-Menu-Lable-Def-Mob">
                          <p>{each.Lable}
                          </p>

                          <div className="Sub-Menu-Def-Mob">
                          </div>
                        </div>

                      </div>



                    </NavLink>


                  ))}


                </div></>}


            </div>

            <NavLink className="nav-links" to="/Testimonials" onClick={removeActive}>Testimonials</NavLink>
            <NavLink className="nav-links" to="/Contact" onClick={removeActive}>Contact</NavLink>
            <NavLink className="nav-links" to="/FAQS" onClick={removeActive}>FAQS</NavLink>
            <NavLink className="nav-links" to="/Blogs" onClick={removeActive}>Blogs</NavLink>


          </div>



          <div className="Login">
            <nav className='Border1'  >

              <NavLink to="/BookDemo" onClick={removeActive}><button className="Login-button" >Book a Demo</button></NavLink>

            </nav>
            <nav className='Border2'  >

              <NavLink to="/Login" onClick={removeActive}><button className="Login-button1" >Login</button></NavLink>

            </nav>
          </div>




        </ul>
        <h1 className="navbar-logo" style={{ zIndex: "4" }}>
          <NavLink to="/">

            <img src={Logo1} alt='' className='navbar-logo1' />
          </NavLink>
        </h1>
        <div className='Search-Side'>
          <div className="Nav-Search">

            {search1 ? <input type="text" placeholder="Search..." /> : ""}
          </div>

          <div className="search-bar" onClick={HandleOnclick}>

            <img typeof='submit' src={Search} alt='' />

          </div>

        </div>

      </div>
    </nav>
  );
};
export default NavigationMob