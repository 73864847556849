import React from 'react'
import "./AboutUS.css"
import AboutBanner from "../Abouts/Banner/AboutBanner"
import AboutDef from './AboutDef/AboutDef'
import AboutmapSlogan from './AboutmapSlogan/AboutmapSlogan'
import AboutMap from './AboutMap/AboutMap'
import AboutMissCommit from './AboutMap/AboutMissCommit/AboutMissCommit'
import AboutTeamSlider from './AboutTeamSlider/AboutTeamSlider'
import ContactForDemo from '../Home/ContactForDemo/ContactForDemo'
const AboutUS = () => {
  return (
    <div className='AboutUS'>
      <AboutBanner />

      <AboutDef />
      <AboutmapSlogan />
      <AboutMap />
      <AboutMissCommit />
      <AboutTeamSlider />
      <div className="ContactForDemo-Home">

        <ContactForDemo />
      </div>
    </div>
  )
}

export default AboutUS