import React from 'react'
import "./OurServiceNewsTemplate.css"
import { OurServiceNewsTemplateDataMid, OurServiceNewsTemplateDataRight } from '../../../DummyData/OurServiceNewsTemplateData'
 
import Icon from "../../../Assests/Logo/FeatureIcon/Group 1000001896.png"
const OurServiceNewsTemplate = () => {
    return (<>
        <div className='OurServiceNewsTemplate'>
            <div className="OurServiceNewsTemplate-Image" data-aos="fade-right">
                {/* <img src={DynamicEmr} alt='' /> */}
                <div className="DynamicOurServiceContact" data-aos="fade-left">
                    <img src={Icon} alt='' />
                    <h3>
                        Give Us a Call
                        to Clairfy your doubt<br />
                        <span>
                            +9779811351171  </span>
                    </h3>
                    <p>
                        “Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in.
                    </p>
                    {/* </div> */}
                </div>
            </div>
            <div className="OurServiceNewsTemplate-Mid" data-aos="fade-up">

                <div className="OurServiceNewsTemplate-Mid-1" >
                    <p>Why Choose Us?</p>
                </div>
                <div className="OurServiceNewsTemplate-Mid-2">
                    <h2>
                        Solution for Quality of software application.
                    </h2>
                    <p>
                        “Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”
                    </p>
                </div>

                <div className="OurServiceNewsTemplate-Mid-3" data-aos="fade-right">
                    {OurServiceNewsTemplateDataMid.map((each, index) => (


                        <div className="OurServiceNewsTemplate-Mid-3-inner" key={index} >

                            <div className="OurServiceNewsTemplate-Mid-3-Image">
                                <img src={each.Icon} alt="" />
                            </div>
                            <div className="OurServiceNewsTemplate-Mid-3-Des">
                                <div className="OurServiceNewsTemplate-Mid-3-Lable">
                                    <h6>
                                        {each.Lable}

                                    </h6>
                                </div>
                                <div className="OurServiceNewsTemplate-Mid-3-Def">
                                    <p>{each.Defination}</p>
                                </div>
                            </div>
                        </div>
                    ))

                    }
                </div>

            </div>

            <div className="OurServiceNewsTemplate-Right">

                {
                    OurServiceNewsTemplateDataRight.map((each, index) => (



                        <div className="OurServiceNewsTemplate-Right-Inner" key={index} data-aos="fade-left">

                            <div className="OurServiceNewsTemplate-Right-Lable">
                                <h6>{each.Lable}</h6>
                            </div>
                            <div className="OurServiceNewsTemplate-Right-Def">
                                <p>{each.Defination}</p>
                            </div>
                            <div className="OurServiceNewsTemplate-Right-profile">

                            </div>
                            <div className="OurServiceNewsTemplate-Right-Name">

                            </div>
                        </div>
                    ))
                }

            </div>


        </div>


    </>

    )
}

export default OurServiceNewsTemplate