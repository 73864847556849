import React from 'react';
import "./Login.css"
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBInput
}
    from 'mdb-react-ui-kit';
import { NavLink } from 'react-router-dom';

function Login1() {
    return (
        <MDBContainer fluid>
            <MDBRow>

                <MDBCol sm='6'>

                    <div className='d-flex  flex-row ps-2 pt-3'>
                        <MDBIcon fas icon="crow fa-3x me-3" style={{ color: '#709085' }} />
                        <span className="h1 fw-bold mb-0" style={{ color: "#014A97" }}>Login</span>
                    </div>

                    <div className='d-flex flex-column justify-content-center  h-custom-2 w-75 pt-4'>
                        <p className='ms-5'>Don't have an account? <NavLink to="/Register" class="link-info" style={{ color: "#E75303" }}>create a new one</NavLink></p>

                        <div className='Email'>
                            <lable className="small mb-4 pb-lg-3 ms-5" style={{ float:'left'}}>Email Address</lable>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' name='Email' id='formControlLg' type='email' size="lg" />
                        </div>
                        <div className="Password">

                            <lable className="small mb-4 pb-lg-3 ms-5" style={{ float: "left" }}>Password</lable>
                            <MDBInput wrapperClass='mb-4 mx-5 w-100' name='Password' id='formControlLg' type='password' size="lg" />
                        </div>

                        <MDBBtn className="mb-4 px-5 mx-5 w-100" size='lg' style={{ backgroundColor: "#E75303" }}>Login</MDBBtn>
                        <p className="small mb-4 pb-lg-3 ms-5" style={{ textAlign: "center" }}><a class="text-muted" href="#!">Forgot password?</a></p>


                    </div>

                </MDBCol>

                <MDBCol sm='6' className='d-none d-sm-block px-0'>
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img3.webp"
                        alt="Login image" className="w-100" style={{ objectFit: 'fill', objectPosition: 'left' ,height:"70vh"}} />
                </MDBCol>

            </MDBRow>

        </MDBContainer>
    );
}

export default Login1;