import React from 'react'
import "./TestimonialTopProduct.css"
import { TestimonialsProductData } from '../../../DummyData/TestimonialsProductData';

const TestimonialTopProduct = () => {
  const lastInde = TestimonialsProductData.length - 1;
  const IndexValue = TestimonialsProductData[lastInde];
  console.log(IndexValue)
  return (
    <div className='TestimonialTopProduct overflow-hidden' >
      <div className='TestimonialTopProduct' key={IndexValue}>

        <div className="TestimonialTopProduct-Photo" data-aos="zoom-out-right">
          <img src={IndexValue.Image} alt='' />
        </div>
        <div className="TestimonialTopProduct-Description" >

          <div className="TestimonialTopProduct-Description-Tiitle">
            <h2 data-aos="zoom-out-left" >{IndexValue.Tittle}</h2>
            <p data-aos="zoom-out-left">{IndexValue.Defination}</p>
          </div>

          <div className="TestimonialTopProduct-Description-Tiitle-SubTittle" data-aos="zoom-out-left">

            <h3>{IndexValue.Lable}</h3>
          </div>
          <div className="TestimonialTopProduct-Description-MetaDescription" data-aos="zoom-out-left">


            <p>{IndexValue.MetaDescription}</p>
          </div>
          <div className="TestimonialTopProduct-Description-Counter d-flex ">
            <div className="TestimonialTopProduct-Description-Counter-1 ">
              <h4 data-aos="zoom-out-left">
                {IndexValue.GoalCount}

              </h4>
              <p data-aos="zoom-out-left">{IndexValue.GoalYear}</p>
            </div>
            <div className="TestimonialTopProduct-Description-Counte-2">
              <h4 data-aos="zoom-out-left">
                {IndexValue.ObjectiveCount}

              </h4>
              <p data-aos="zoom-out-left">{IndexValue.ObjectiveYear}</p>

            </div>


          </div>
          <button data-aos="flip-right">View Success Story</button>

        </div>

      </div>
    </div>
  )
}

export default TestimonialTopProduct