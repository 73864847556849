import A1 from "../Assests/Logo/FeatureIcon/Group.png"
import A2 from "../Assests/Logo/FeatureIcon/material-symbols-light_app-registration-outline-sharp.png"
import A3 from "../Assests/Logo/FeatureIcon/Group 1000001896.png"
import A4 from "../Assests/Logo/FeatureIcon/iconoir_journal.png"
import A5 from "../Assests/Logo/FeatureIcon/Group 1000001896.png"
export const FeaturesSliderData=[
    {
        Image:A1,
        Lable:"Pharmacy & Spectate Billing",
        Defination:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in.”",

    },
    {
        Image:A4,
        Lable:"Appointments",
        Defination:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in.”",

        
    },
    {
        Image:A2,
        Lable:"Registrations",
        Defination:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in.”",

        
    },
    {
        Image:A3,
        Lable:"Scanning & Coding",
        Defination:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in.”",

    },
    // {
    //     Image:A2,
    //     Lable:"Support",
    //     Defination:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.”",
        
    // },
];