export const FooterData = [
      
    {
        Link: "/AboutUs",
        Components: "About Us",
    },
    {
        Link: "/Blog",
        Components: "Blog",
    },
    {
        Link: "/TermsAndConditions",
        Components: "Terms And Conditions",
    },
    {
        Link: "/PrivacyPolicy",
        Components: "Privacy Policy",
    },
    {
        Link: "/Review",
        Components: "Review",
    },
    
  
];
export const FooterData2 = [
    {
        Link: "/Contact",
        Components: "Contact Us",
    },
    {
        Link: "/Portfolio",
        Components: "Portfolio",
    },
    {
        Link: "/Blog",
        Components: "Blog",
    },
    {
        Link: "/OurTeam",
        Components: "Our Team",
    },
  
];
export const FooterData3 = [
   
    // {
    //     Link: "/Portfolio",
    //     Components: "Portfolio",
    // },
    // {
    //     Link: "/Blog",
    //     Components: "Blog",
    // },
    // {
    //     Link: "/OurTeam",
    //     Components: "Our Team",
    // },
    {
        Link: "/Review",
        Components: "SSF",
    },
    {
        Link: "/Review",
        Components: "Insurance",
    },
];
 