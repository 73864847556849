import React, { useState } from 'react'
import "./BlogsLatestPost.css"
import { BlogsData } from '../../../DummyData/BlogsData'
const BlogsLatestPost = () => {
    const [Data, setData] = useState(true)
    const HandleOnclick = () => {
        setData(Data?false:true)
    }
   
    return (
        <div className="BlogsLatestPost-Main">
            <div className="BlogLatestpost-Lable">
                <h2>Latest post</h2>
            </div>

            <div className='BlogsLatestPost-inner'>
                {Data ? <>{BlogsData.slice(0, 6).map((each, index) => (
                    <div className="BlogsLatestPost-Des" key={index}>

                        <div className="BlogsLatestPost-Photo">
                            <img src={each.Photo} alt='' />

                        </div>
                        <div className="BlogsLatestPost-Des-Inner">
                            <div className="BlogsLatestPost-Profile">
                                <img src={each.Profile} alt='' />
                                <div className="BlogLatestPost-Profile1">
                                    <p>{each.Name}</p>
                                    <p>{each.Date}</p>
                                </div>
                               
                            </div>

                            <div className="BlogsLatestPost-Heading">
                                <h3>{each.Heading}</h3>

                            </div>

                        </div>
                    </div>
                ))}
                </> : <>{BlogsData.map((each, index) => (
                    <div className="BlogsLatestPost-Des" key={index}>

                        <div className="BlogsLatestPost-Photo">
                            <img src={each.Photo} alt='' />

                        </div>
                        <div className="BlogsLatestPost-Des-Inner">
                            <div className="BlogsLatestPost-Profile">
                                <img src={each.Profile} alt='' />
                                <p>{each.Name}</p>
                                <p>{each.Date}</p>
                            </div>

                            <div className="BlogsLatestPost-Heading">
                                <h3>{each.Heading}</h3>

                            </div>

                        </div>
                    </div>
                ))}</>}

            </div>
            <button onClick={HandleOnclick}>{Data?"Load More":'Load Less'}</button>
        </div>

    )
}

export default BlogsLatestPost