import React from 'react'
import "./CompareAnalysis.css"
import { NavLink } from 'react-router-dom'
import Photo from "../../../../Assests/Products/Hospitalmang/Screenshot (24).png"
const CompareAnalysis = () => {
    return (
        <div className='CompareAnalysis'>
            <div className="CompareAnalysis-1" data-aos="zoom-in-right">
                <h2>
                Variety of report and analysis Get 
                Through Reviews Faster.
                </h2>
                <p>
                Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.
                </p>
            </div>
            <div className="CompareAnalysis-2" data-aos="zoom-in-left">
                <div className="CompareAnalysis-Def">
                    <h2>
                    Comprehensive Reporting and Real Time Data Analysis
                    </h2>
                    <p>
                    Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.
                    </p>
                    <NavLink to="/BookDemo" data-aos="flip-left">
                        <button className='CompareAnalysis-Def-But' >Book a Demo</button>
                    </NavLink>
                </div>
                <div className="CompareAnalysis-Photo">
                    <img src={Photo} alt="" />
                </div>
            </div>
        </div>
    )
}

export default CompareAnalysis