import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ScrollProgressCircle = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercent = (scrollTop / docHeight) * 100;
    setScrollPercentage(scrollPercent);
  };
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setScrollPercentage(0);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ width: 80, height: 80, position: 'fixed', bottom: 20, right: 20 }}onClick={handleClick}>
      <CircularProgressbar
        value={scrollPercentage}
        text={`${Math.round(scrollPercentage)}%`}
        styles={buildStyles({
          textSize: '16px',
          pathColor: `rgba(62, 152, 199, ${scrollPercentage / 100})`,
          textColor: '#f88',
          trailColor: '#d6d6d6',
        })}
      />
    </div>
  );
};

export default ScrollProgressCircle;
