import Videos from "../../../Assests/Vidisdsk.mp4"
import VideoPoster from "../../../Assests/Videos/Poster/Group 1000002828.png"
import Play from "../../../Assests/Logo/Vector.png"

import React, { useRef, useState } from 'react';
import './VideoSlider.css';

const VideoSlider = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="video-container" data-aos="fade-up">
            
            <video
                ref={videoRef}
                className="video"
                src={Videos}
                poster={VideoPoster}
                onClick={handlePlayPause}
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
            />
            {!isPlaying && (
                <button className="play-button" onClick={handlePlayPause}>
                    <img src={Play} alt="Play" />
                </button>
            )}
        </div>
    );
};


export default VideoSlider;
