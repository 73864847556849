import "./ClientStoryTab.css";
import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import ClientslidesData from "../../../../../DummyData/ClientSliderData";
import StarRating from "../../../../../StarRating/StarRating";



function ClientStoryTab() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="ClientStoryTab">
      <div className="ClientStoryTab-Header">
        <p>Our Happy clients ,real stories!  </p>
      </div>
      <div className="ClientStoryTab-SubSlider">

        <Slider dots {...settings}>
          {/* <div className="ClientStoryTab-Inner"> */}

          {ClientslidesData.map((each, idx) => (
            <div className={idx === imageIndex ?"slide" : "slide"}>




              <div className="ClientStorie-Slider-Inner">
                <div className="ClientStorie-Note">
                  <p>
                    {each.Note}
                  </p>
                  <div className="ClientStorie-Rating">
                    {/* {each.Ratings} */}
                    <StarRating/>
                  </div>
                </div>

                <div className="ClientStorie-Profile">
                  <div className="Client-Profile">
                    <img src={each.Profile} alt="" />
                  </div>
                  <div className="Client-Details" style={{

                  }}>
                    <div className="Client-Name">
                      <p>{each.Name}</p>

                    </div>
                    <div className="Client-Position">
                      <p >{each.Details}</p>

                    </div>
                  </div>
                </div>
              </div>




            </div>

          ))}
          {/* </div> */}

        </Slider>
      </div>

    </div>
  );
}

export default ClientStoryTab;





