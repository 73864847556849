import React, { useState } from 'react';
import {Link} from "react-router-dom"
import "./BookDemoRegForm.css"
const BookDemoRegForm = () => {
    const [formData, setFormData] = useState({
        input1: '',
        input2: '',
        input3: '',
        input4: '',
        input5: '',
        input6: '',
        textarea: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data:', formData);
    };
    return (
        <div className='BookDemoRegForm'>

            <form onSubmit={handleSubmit}>
                <div className="BookDemoRegForm-Inner-1" style={{
                    justifyContent: "space-between"
                }}>


                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="input1">Name:</label>
                        <input type="text" className='input' id="input1" name="input1" value={formData.input1} onChange={handleChange} required />
                    </div>
                    <div className="form-group" >
                        <label htmlFor="input2">City:</label>
                        <input type="text" className='input' id="input2" name="input2" value={formData.input2} onChange={handleChange} required/>
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="input3">Mobile Number:</label>
                        <input type="text" className='input' id="input3" name="input3" value={formData.input3} onChange={handleChange} required/>
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="input4">Email Address:</label>
                        <input type="email" className='input' id="input4" name="input4" value={formData.input4} onChange={handleChange} required/>
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="input5">Business Name:</label>
                        <input type="text" className='input' id="input5" name="input5" value={formData.input5} onChange={handleChange} required/>
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="input6">Business Type:</label>
                        <input type="text" className='input' id="input6" name="input6" value={formData.input6} onChange={handleChange} />
                    </div>
                </div>

                <div className="BookDemoRegForm-Inner-2">
                    {/* <div className="form-group-2"> */}
                        <lable htmlFor="textarea">Remarks:</lable>
                        <textarea id="textarea" className='RegtexArea' name="textarea" value={formData.textarea} onChange={handleChange}></textarea>
                    {/* </div> */}

                </div>
                <div className="BookDemoRegForm-Def">
                    <p>
                        <a href='/'>Dynamic EMR</a> focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management. Our software automates administrative tasks, reduces paperwork, and optimizes workflows, enabling healthcare providers to increase efficiency and productivity.
                    </p>

                </div>
                <nav className='Request'  >

                    <Link to="/#" ><button className="Req-Demo" >Book a Demo</button></Link>

                </nav>
            </form>

        </div>

    )
}

export default BookDemoRegForm