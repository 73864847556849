import Icon from "../../Assests/Logo/FeatureIcon/iconoir_journal.png"
const NavPath = [
    {
        Link: "/",
        Lable: "Home",
        ProductData: [],

    },
    {
        Link: "/Aboutsus",
        Lable: "Abouts",
        ProductData: [],

    },
    {
        Link: "/Service",
        Lable: "Services",
        ProductData: [],

    },
    {
        // Link: "/Products",
        Lable: "Products",
      
    },

    {
        Link: "/Testimonials",
        Lable: "Testimonials",
        ProductData: [],

    },
    {
        Link: "/Contact",
        Lable: "Contact Us",
        ProductData: [],

    },
    {
        Link: "/FAQS",
        Lable: "FAQS",
        ProductData: [],

    },
    {
        Link: "/Blogs",
        Lable: "Blogs",
        ProductData: [],

    },
    {
        Link: "",
        Lable: "",
        ProductData: [],

    },

]
export default NavPath;
export const ProductData= [{
    Link: "/Hospitals",
    Icon: Icon,

    Lable: "Hospitals",
    Defination: "Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
},
{
    Link: "/Patients",
    Icon: Icon,

    Lable: "Patients",
    Defination: "Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
},
{
    Link: "/Laboratory",
    Icon: Icon,

    Lable: "Laboratory",
    Defination: "Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
},
{
    Link: "/EMR",
    Icon: Icon,

    Lable: "EMR",
    Defination: "Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
},
]