import React from 'react'
import "./BlogsType.css"
import { BlogsData } from '../../../DummyData/BlogsData'
import LandingBlogs from '../LandingBlogs/LandingBlogs';
const BlogsType = () => {
    return (<>


        <LandingBlogs />

        <div className='BlogsType-Main'>
            {BlogsData.map((each, index) => (
                <div className="BlogsType-Des" key={index}>

                    <div className="BlogsType-Photo">
                        <img src={each.Photo} alt='' />

                    </div>
                    <div className="BlogsType-Des-Inner">
                        <div className="BlogsType-Profile">
                            <img src={each.Profile} alt='' />
                            <div className="BlogLatestPost-Profile1">
                            <p>{each.Name}</p>
                            <p>{each.Date}</p>
                            </div>
                           
                        </div>

                        <div className="BlogsType-Heading">
                            <h3>{each.Heading}</h3>

                        </div>

                    </div>
                </div>
            ))}
        </div>
    </>

    )
}

export default BlogsType