import React, { useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"
import "./RegForm.css"
const RegForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        phone: '',
        email: '',
        business_name: '',
        business_type: '',
        message: ''
    });
    const [errors, setErrors] = useState({});


    const validate = () => {
        let tempErrors = {};
        if (!formData.name) tempErrors.name = "Name is required";
        if (!formData.subject) tempErrors.subject = "City is required";
        if (!formData.phone) tempErrors.phone = "Phone Number is required";
        if (!formData.business_name) tempErrors.business_name = "Business Name is required";
        if (!formData.business_type) tempErrors.business_type = "Business Type is required";

        if (!formData.email) {
            tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email is invalid";
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await axios.post("https://api.dynamicemr.net/api/contact", formData)
                if (response.status === 200) {
                    alert('Form submitted successfully!');
                    setFormData({
                        name: '',
                        subject: '',
                        phone: '',
                        email: '',
                        business_name: '',
                        business_type: '',
                        message: ''
                    });

                } else {
                    alert('Failed to submit form');
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('An error occurred while submitting the form');
            }
        }
    };

    return (
        <div className='RegForm'>

            <form onSubmit={handleSubmit}>
                <div className="RegForm-Inner-1" style={{
                    justifyContent: "space-between"
                }}>


                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="name">Name:</label>
                        <input type="text" className='input' id="name" name="name" value={formData.name} onChange={handleChange} />
                        {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="subject">City:</label>
                        <input type="text" className='input' id="subject" name="subject" value={formData.subject} onChange={handleChange} />
                        {errors.subject && <p className="error">{errors.subject}</p>}
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="phone">Mobile Number:</label>
                        <input type="text" className='input' id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                        {errors.phone && <p className="error">{errors.phone}</p>}
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="email">Email Address:</label>
                        <input type="text" className='input' id="email" name="email" value={formData.email} onChange={handleChange} />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="business_name">Business Name:</label>
                        <input type="text" className='input' id="business_name" name="business_name" value={formData.business_name} onChange={handleChange} />
                        {errors.business_name && <p className="error">{errors.business_name}</p>}
                    </div>
                    <div className="form-group" data-aos="fade-left">
                        <label htmlFor="business_type">Business Type:</label>
                        <input type="text" className='input' id="business_type" name="business_type" value={formData.business_type} onChange={handleChange} />
                        {errors.business_type && <p className="error">{errors.business_type}</p>}
                    </div>
                </div>
                <div className="RegForm-Inner-2">
                    <label htmlFor="message">Remarks:</label>
                    <textarea id="message" className='RegtexArea' name="message" value={formData.message} onChange={handleChange}></textarea>
                </div>
                <div className="RegForm-Def">
                    <p>
                        <a href='/'>Dynamic EMR</a> focuses on transforming healthcare management by streamlining hospital operations, enhancing patient care, and providing comprehensive data management. Our software automates administrative tasks, reduces paperwork, and optimizes workflows, enabling healthcare providers to increase efficiency and productivity.
                    </p>

                </div>


                <button type='submit' className="Req-Demo" >Book a Demo</button>


            </form>

        </div>

    )
}

export default RegForm