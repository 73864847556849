import React from 'react'
import "./HospitalHealthMang.css"
import { OurServiceNewsTemplateDataRight } from '../../../../DummyData/OurServiceNewsTemplateData'
import Photo from "../../../../../Components/Assests/Products/Hospitalmang/Group 1000002850.png"
const HospitalHealthMang = () => {
    return (
        <div className='HospitalHealthMang'>
            <div className="HospitalHealthMang-Heading">
                <p className='HospitalHealthMang-Heading-1'>
                    Transforming Healthcare Management
                </p>
                <p className='HospitalHealthMang-Heading-2'>
                    Dynamic EMR focuses on transforming healthcare management system
                </p>
            </div>

            <div className="HospitalHealthMang-Des">
                <div className="HospitalHealthMang-1">
                    <img src={Photo} alt='' />
                </div>
                <div className="HospitalHealthMang-2">
                    {/* <div className="HospitalHealthMang-2"> */}

                    {
                        OurServiceNewsTemplateDataRight.map((each, index) => (



                            <div className="HospitalHealthMang-2-Inner" key={index} data-aos="fade-left"  >

                                <div className="HospitalHealthMang-2-Lable">
                                    <h6>{each.Lable}</h6>
                                </div>
                                <div className="HospitalHealthMang-2-Def">
                                    <p>{each.Defination}</p>
                                </div>
                                <div className="HospitalHealthMang-2-profile">

                                </div>
                                <div className="HospitalHealthMang-2-Name">

                                </div>
                            </div>
                        ))
                    }

                    {/* </div> */}
                </div>
            </div>

        </div>
    )
}

export default HospitalHealthMang