import Last from "../Assests/About/Team/pexels-any-lane-5945840.jpg"
export const TestimonialsProductData=[
    {
        Image:Last,
        
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        
        ObjectiveCount:"400",
    },
    {
        Image:"",
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
    {
        Image:Last,
        
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
    {
        Image:Last,
        
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
    {
        Image:Last,
        Tittle:"Dynamic EMR",
        Defination:"Our software streamlines hospital workflows.",

        Lable:"Comprehensive Reporting and Real Time Data Analysis",
        MetaDescription:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments. Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
        GoalYear:"Development goals created in 2024.",
        ObjectiveYear:"objective created in 2024.",
        Description:"",
        GoalCount:"250+",
        ObjectiveCount:"400",
    },
 
];