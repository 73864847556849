

import React from 'react'
import "./Features.css"
import { FeaturesSliderData } from '../../../DummyData/FeaturesSliderData'
const Features = () => {
    return (<div className='Features'>
        <div className="Feature-Heading">
            <p>What makes us unique?</p>
        </div>
        <div className='Slider-Main-Container'>

            {FeaturesSliderData.map((each, index) => {
                return (
                    <div className="Slider-Image-Lable-Container" key={index}>
                        <div className="Features-Inner" data-aos="flip-left">
                            <div className='Slider-Image-Container'>
                                <img src={each.Image} alt=""
                                />
                            </div>
                            <div className="Features-Lable">
                                <p>{each.Lable}</p>
                            </div>
                            <div className="Features-Defination">
                                <p>{each.Defination}</p>
                            </div>

                            <p>{each.Name}</p>
                        </div>

                    </div>
                );
            })}
            <hr />

        </div>
    </div>
    )
}

export default Features