import React from 'react'
import "./OurService.css"
import OurServiceData from "../../../DummyData/OurServiceData"
import Arrows from "../../../Assests/OurService/Counter/Vector (3).png"
const OurService = () => {
    return (
        <div className='OurService'>
            <div className="OurService-Lable" data-aos="flip-left">
                <h6>Our Service</h6>
            </div>

            <div className="OurService-Des">

                {OurServiceData.map((item, index) => (


                    <div className="OurService-Des-Inner" key={index} data-aos="flip-right">
                        <div className="OurService-Des-Icon">
                            <img src={item.Icon} alt="" />
                        </div>
                        <div className="OurService-Des-Lable">
                            <p>{item.Lable}</p>
                        </div>
                        <div className="OurService-Des-MetaDes">
                            <p>{item.MetaDescription}</p>

                        </div>
                        <div className="OurService-Des-But">

                            <div className="OurService-Des-Read">
                                <button>Read More</button>
                            </div>
                            <div className="OurService-Des-Arrow">
                                <img src={Arrows} alt="" />
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>

        </div >




    )
}

export default OurService