import React from 'react'
import "./TestimonialBanner.css"
import Next from "../../../Assests/quotes1.png"
import Next1 from "../../../Assests/quotes2.png"

const TestimonialBanner = () => {
    console.log("testomial-banner")
    return (

        <div className='TestimonialBanner'>
            <div className="TestimonialBanner-Banner">


                <img src={Next} style={{ width: "5vw", position: "relative", right: "30%" }} alt='' />


                <h3>
                    Our Testimonials
                </h3>
                <p>
                    Lorem ipsum dolor sit amet consectetur. Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phasellus ultricie Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phase
                </p>

                <img style={{ width: "5vw", position: "relative",left:"30%" }} src={Next1} alt='' />

            </div>
            <div className="TestimonialBanner-Des">
                <div className="TestimonialBanner-Des-Lable" data-aos="zoom-out-right">
                    <p>
                        Solution for Quality of software application.

                    </p>
                </div>
                <div className="TestimonialBanner-Des-Def" data-aos="zoom-out-left">
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phasellus ultricie Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phase Velit hac phasellus ultricie Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phase.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TestimonialBanner