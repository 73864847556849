export const ProductBannerdata=[
    {
        Lable:"Enhanced Efficiency",
        Description:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments."
    },
    {
        Lable:"Comprehensive Reporting",
        Description:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
    },
    {
        Lable:"Enhanced Efficiency",
        Description:"Our software streamlines hospital workflows by automating administrative tasks, reducing paperwork, and improving coordination among departments.",
    },
];