import React from 'react'
import "./LandingBlogs.css"
import { BlogsData } from '../../../DummyData/BlogsData';
const LandingBlogs = () => {
    const lastInde = BlogsData.length - 1;
    const IndexValue = BlogsData[lastInde];
    console.log(IndexValue)
    return (<>
        {/* {IndexValue.map((lastIndex) => ( */}

        <div className='LandingBlogs' key={IndexValue}>

            <div className="LandingBlogs-Photo">
                <img src={IndexValue.Photo} alt='' />
            </div>
            <div className="LandingBlogs-Description">
                <h3>{IndexValue.Heading}</h3>
                <p>{IndexValue.MetaDescription}</p>
                <div className="Profile-Container">
                    <img src={IndexValue.Profile} alt='' />

                    <div className="Profile-Container1">
                        <p>{IndexValue.Name}</p>
                        <p>{IndexValue.Date}</p>
                    </div>

                </div>
            </div>

        </div>
        {/* ))} */}

    </>

    )
}

export default LandingBlogs