export const MissionData = [
    {
        Lable: "Unmatched service",
        Defination: "Support corporate clients and financial investors with their growth strategy and international development.",
    },
    {
        Lable: "Specific",
        Defination: "ConsultUs’ core expertise lies in the ability to support our clients in understanding, analysing and executing commercial and investment strategies in specific markets. ",
    },
    {
        Lable: "Experience",
        Defination: "Experience in working with and assisting a wide range of clients from international corporations to small/medium-sized businesses, from large corporate debt providers to mid-market private equity investors. ",
    },
    {
        Lable: "Technology",
        Defination: "The best combination of technology and people.",
    },
]
export const CommitmentData = [
    {
        Lable: "Ethics",
        Defination: "Ethics for ConsultUs means ensuring our customers the confidentiality and uniqueness of the service they are provided. A client must be reassured by the fact that the service provided to them is made-to-measure and will not be recycled for their competitors.",
    },
    {
        Lable: "Quality",
        Defination: "ConsultUs is committed to ensuring that our advice and recommendations are based on the best combination of methods, information research, creativity and internal quality assurance. ",
    },
    {
        Lable: "Continuity",
        Defination: "ConsultUs considers that the continuity of relations on the long term with its clients is the guarantee of the satisfaction of these and the quality of the services provided.",
    },
]