import SoftIcon from "../Assests/OurService/ic_outline-settings.png"
import SoftIcon1 from "../Assests/OurService/Group.png"
import SoftIcon2 from "../Assests/OurService/Group (1).png"
import SoftIcon3 from "../Assests/OurService/Vector (1).png"
import SoftIcon4 from "../Assests/OurService/Vector (2).png"
import SoftIcon5 from "../Assests/OurService/Vector (3).png"
import SoftIcon6 from "../Assests/OurService/Vector (4).png"
import SoftIcon7 from "../Assests/OurService/Vector (5).png"
import SoftIcon8 from "../Assests/OurService/ic_outline-settings.png"

const OurService=[
    {
        Icon:SoftIcon,
        Lable:"Software Development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon1,
        Lable:"Mobile app development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon2,
        Lable:"Website Development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon3,
        Lable:"Internet of things",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon4,
        Lable:"Software and mobile application development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon5,
        Lable:"Business operations and supports",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon6,
        Lable:"Data Processing and analysis",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon7,
        Lable:"Automation, analysis and artificial inteligence",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon8,
        Lable:"Software Development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon2,
        Lable:"Software Development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon4,
        Lable:"Software Development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    {
        Icon:SoftIcon7,
        Lable:"Software Development",
        MetaDescription:"“Lorem ipsum dolor sit amet consectetur. Sit eget morbi et fhdh proin quam molestie. Sollicitudin in sit turpis in morbi morbi in. Volutpat hac egestas nulla dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra.  dolor dshbdh scelerisque iaculis fdhh suspendisse pharetra. suspendisse pharetra.  dolor dshbdh scelerisque iaculis “”",
        Description:"",
    },
    
];
export default OurService