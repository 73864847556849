import React from 'react'
import "./OurServiceBanner.css"
import Next from "../../../Assests/faq-ezgif.com-gif-maker.gif"
import Next1 from "../../../Assests/helpdesk-ezgif.com-gif-maker.gif"

const OurServiceBanner = () => {
    return (
        <div className='OurServiceBanner'>
            <div className="OurServiceBanner-Banner">
                {/* <img src={Next} style={{ width: "7vw", position: "relative", right: "30%" }} alt='' /> */}
                <img style={{ width: "7vw", position: "relative", right: "30%" }} src={Next1} alt='' />

                <h3>
                    Our Service
                </h3>
                <p>
                    Lorem ipsum dolor sit amet consectetur. Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phasellus ultricie Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phase
                </p>
               
            </div>
            <div className="OurServiceBanner-Des">
                <div className="OurServiceBanner-Des-Lable" data-aos="fade-right">
                    <p>
                        Solution for Quality of software application.

                    </p>
                </div>
                <div className="OurServiceBanner-Des-Def" data-aos="fade-left">
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phasellus ultricie Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phase Velit hac phasellus ultricie Eu suspendisse cras magna ultricies ac suspendisse. Velit hac phase.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default OurServiceBanner