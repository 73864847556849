 import Pralad from "../Assests/About/Team/IMG20230427094115.jpg"
 import A1 from "../Assests/About/Team/pexels-alleksana-4113795.jpg"
 import A2 from "../Assests/About/Team/pexels-any-lane-5945840.jpg"

 import A3 from "../Assests/About/Team/pexels-any-lane-5946066.jpg"

 import A4 from "../Assests/About/Team/pexels-enginakyurt-10112726.jpg"

 import A5 from "../Assests/About/Team/pexels-pixabay-65256.jpg"
 import A6 from "../Assests/About/Team/pexels-roman-odintsov-4869085.jpg"
 import A7 from "../Assests/About/Team/pexels-suzyhazelwood-1510392.jpg"
 import A8 from "../Assests/About/Team/pexels-wendyaffieplaas-1178610.jpg"
 import Pralad1 from "../Assests/About/Team/_d141f5c8-8365-4ee9-9dee-9c287d5f68ce.jpeg"

 const AboutTeamSliderData = [
    {
        Photo:A1,
        Name: "Dilip",
        Position: "Software Developer",
    },
    {
        Photo:A2,
        Name: "som Narayan Shah",
        Position: "",
    },
    {
        Photo:Pralad1,
        Name: "Parbat Raj Jha",
        Position: "King of Gulab Jamun",
    },
    
     

    {
        Photo:A5,
        Name: "Anshu Malik",
        Position: "Queen of Support",
    }, {
        Photo:A4,
        Name: "Anjeeta pokhrel",
        Position: "Princess of Insurance",
    },
    {
        Photo:A6,
        Name: "Shalu Malik",
        Position: "Princess of Accounting"
    },
    {
        Photo:A7,
        Name: "Surya Chaudhary",
        Position: "Report-Manager",
    },
    {
        Photo:A8,
        Name: "Mukesh kumar Ray",
        Position: "lab-Manager",
    },

    {
        Photo:Pralad,
        Name: "Pralad chudhary",
        Position: "King Of Table",
    },

];
export default  AboutTeamSliderData